import { return_01, return_02, return_03, return_04, return_05, return_06, return_07, return_08, return_09, return_10 } from "../../_components/Imagepath";

export default {
  Data: [
    {
      Id: "1",
      Item: "Lenovo 3rd Generation",
      Code: "P125389",
      Category: "Laptop",
      Units: "Inches",
      Quantity: "2",
      Sales: "$253.00",
      Purchase: "$248.00",
      img: return_01,
    },
    {
      Id: "2",
      Item: "Nike Jordan",
      Code: "P125390",
      Category: "Shoes",
      Units: "Pieces",
      Quantity: "4",
      Sales: "$360.00",
      Purchase: "$350.00",
      img: return_02,
    },
    {
      Id: "3",
      Item: "Apple Series 5 Watch",
      Code: "P125391",
      Category: "Accessories",
      Units: "Inches",
      Quantity: "7",
      Sales: "$724.00",
      Purchase: "$700.00",
      img: return_03,
    },
    {
      Id: "4",
      Item: "Amazon Echo Dot",
      Code: "P125392",
      Category: "Electronics",
      Units: "Box",
      Quantity: "3",
      Sales: "$210.00",
      Purchase: "$200.00",
      img: return_04,
    },
    {
      Id: "5",
      Item: "Lobar Handy",
      Code: "P125393",
      Category: "Furnitures",
      Units: "Kilograms",
      Quantity: "1",
      Sales: "$155.00",
      Purchase: "$150.00",
      img: return_05,
    },
    {
      Id: "6",
      Item: "Woodcraft Sandal",
      Code: "P125394",
      Category: "Bags",
      Units: "Inches",
      Quantity: "2",
      Sales: "$253.00",
      Purchase: "$248.00",
      img: return_06,
    },
    {
      Id: "7",
      Item: "Black Slim 200",
      Code: "P125394",
      Category: "Furnitures",
      Units: "Inches",
      Quantity: "2",
      Sales: "$253.00",
      Purchase: "$248.00",
      img: return_07,
    },
    {
      Id: "8",
      Item: "Red Premium Handy",
      Code: "P125394",
      Category: "Bags",
      Units: "Inches",
      Quantity: "2",
      Sales: "$253.00",
      Purchase: "$248.00",
      img: return_08,
    },
    {
      Id: "9",
      Item: "Bold V3.2",
      Code: "P125394",
      Category: "Electronics",
      Units: "Inches",
      Quantity: "2",
      Sales: "$253.00",
      Purchase: "$248.00",
      img: return_09,
    },
    {
      Id: "10",
      Item: "Iphone 14 Pro",
      Code: "P125394",
      Category: "Phone",
      Units: "Inches",
      Quantity: "2",
      Sales: "$253.00",
      Purchase: "$248.00",
      img: return_10,
    },
  ],
};
