import { company_01, company_02, company_03, company_04, company_05, company_06, company_07, company_08, company_09, company_10 } from "../../_components/Imagepath";

export default {
  subscribtiondata: [
      {
        "id": "1",
        img: company_01,
        "Subscriber": "Hermann Groups",
        "Plan": "Advanced (Monthly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Paypal",
        "Amount": "$19.99",
        "RegisteredOn": "15 Jan 2024",
        "ExpiringOn": "15 Feb 2024",
        "Status": "Paid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      },
      {
        "id": "2",
        img: company_02,
        "Subscriber": "Skiles LLC",
        "Plan": "Basic (Monthly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Credit Card",
        "Amount": "$499.99",
        "RegisteredOn": "02 Feb 2024",
        "ExpiringOn": "02 Mar 2024",
        "Status": "Paid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      },
      {
        "id": "3",
        img: company_03,
        "Subscriber": "Kerluke Group",
        "Plan": "Basic (Monthly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Paypal",
        "Amount": "$19.99",
        "RegisteredOn": "18 Mar 2024",
        "ExpiringOn": "18 Mar 2024",
        "Status": "Paid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      },
      {
        "id": "4",
        img: company_04,
        "Subscriber": "Schowalter Group",
        "Plan": "Premium (Yearly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Credit Card",
        "Amount": "$6549.99",
        "RegisteredOn": "20 Apr 2024",
        "ExpiringOn": "20 Apr 2024",
        "Status": "Paid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      },
      {
        "id": "5",
        img: company_05,
        "Subscriber": "Accentric Global",
        "Plan": "Advanced (Monthly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Debit Card",
        "Amount": "$499.99",
        "RegisteredOn": "12 May 2024",
        "ExpiringOn": "12 May 2024",
        "Status": "Paid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      },
      {
        "id": "6",
        img: company_06,
        "Subscriber": "Dexter Matrix",
        "Plan": "Enterprise (Monthly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Paypal",
        "Amount": "$499.99",
        "RegisteredOn": "02 Feb 2024",
        "ExpiringOn": "Expired",
        "Status": "Unpaid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      },
      {
        "id": "7",
        img: company_07,
        "Subscriber": "Emporis Technologies",
        "Plan": "Basic (Monthly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Credit Card",
        "Amount": "$499.99",
        "RegisteredOn": "02 Feb 2024",
        "ExpiringOn": "02 Mar 2024",
        "Status": "Paid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      },
      {
        "id": "8",
        img: company_08,
        "Subscriber": "Beacon Softwares",
        "Plan": "Basic (Monthly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Credit Card",
        "Amount": "$499.99",
        "RegisteredOn": "02 Feb 2024",
        "ExpiringOn": "02 Mar 2024",
        "Status": "Paid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      },
      {
        "id": "9",
        img: company_09,
        "Subscriber": "Global tech",
        "Plan": "Basic (Monthly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Credit Card",
        "Amount": "$499.99",
        "RegisteredOn": "02 Feb 2024",
        "ExpiringOn": "02 Mar 2024",
        "Status": "Paid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      },
      {
        "id": "10",
        img: company_10,
        "Subscriber": "High Tech Lead",
        "Plan": "Basic (Monthly)",
        "BillingCycle": "30 Days",
        "PaymentGateway": "Credit Card",
        "Amount": "$499.99",
        "RegisteredOn": "02 Feb 2024",
        "ExpiringOn": "02 Mar 2024",
        "Status": "Paid",
        "Invoice": "",
        "Action": "Download\n                                \n                              \n                              \n                                \n                                  \n                                    \n                                    Delete"
      }
    
  ],
};
