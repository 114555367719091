import { img1, img2, img3, img6, img8, img9 } from "../../_components/Imagepath";

export default {
    Data: [
      { 
        id:1,
        key:1,
        Credit:"#4987",
        Name: "John Smith",
        Img: img1,
        email: "john@example.com",
        Amount: "$1,54,220",
        Payment: "Cash",
        Created:"23 Mar 2023",
        Status:"Paid",
        StatusPending:"Pending",
        StatusOverdue:"Overdue",
        StatusDraft:"Draft",
        StatusRecurring:"Recurring",
        StatusCancelled:"Cancelled",
        Action:""
      },
      {
        id:2,
        key:2,
        Credit:"#4988",
        Name: "Robert",
        Img: img2,
        email: "robert@example.com",
        Amount: "$1,54,220",
        Payment: "Cash",
        Created:"23 Mar 2023",
        Status:"Pending",
        StatusPending:"Pending",
        StatusOverdue:"Overdue",
        StatusDraft:"Draft",
        StatusRecurring:"Recurring",
        StatusCancelled:"Cancelled",
        Action:""
      },
      {
        id:3,
        key:3,
        Credit:"#4989",
        Name: "Johnny",
        Img: img3,
        email: "johnny@example.com",
        Amount: "$1,54,220",
        Payment: "Cash",
        Created:"23 Mar 20230",
        Status:"Overdue",
        StatusPending:"Pending",
        StatusOverdue:"Overdue",
        StatusDraft:"Draft",
        StatusRecurring:"Recurring",
        StatusCancelled:"Cancelled",
        Action:""
      },
      {
        id:4,
        key:4,
        Credit:"#4990",
        Name: "Randall",
        Img: img6,
        email: "randall@example.com",
        Amount: "$1,54,220",
        Payment: "Cash",
        Created:"23 Mar 2023",
        Status:"Draft",
        StatusPending:"Pending",
        StatusOverdue:"Overdue",
        StatusDraft:"Draft",
        StatusRecurring:"Recurring",
        StatusCancelled:"Cancelled",
        Action:""
      },
      {
        id:5,
        key:5,
        Credit:"#4991",
        Name: "Russell Copeland",
        Img: img8,
        email: "russell@example.com",
        Amount: "$1,54,220",
        Payment: "Cash",
        Created:"23 Mar 2023",
        Status:"Recurring",
        StatusPending:"Pending",
        StatusOverdue:"Overdue",
        StatusDraft:"Draft",
        StatusRecurring:"Recurring",
        StatusCancelled:"Cancelled",
        Action:""
      },
      {
        id:6,
        key:6,
        Credit:"#4992",
        Name: "Barbara Moore",
        Img: img9,
        email: "barbara@example.com",
        Amount: "$1,54,220",
        Payment: "Cash",
        Created:"23 Mar 2023",
        Status:"Cancelled",
        StatusPending:"Pending",
        StatusOverdue:"Overdue",
        StatusDraft:"Draft",
        StatusRecurring:"Recurring",
        StatusCancelled:"Cancelled",
        Action:""
      },
      {
        id:7,
        key:7,
        Credit:"#4993",
        Name: "John Smith",
        Img: img1,
        email: "john@example.com",
        Amount: "$1,54,220",
        Payment: "Cash",
        Created:"23 Mar 2023",
        Status:"Paid",
        StatusPending:"Pending",
        StatusOverdue:"Overdue",
        StatusDraft:"Draft",
        StatusRecurring:"Recurring",
        StatusCancelled:"Cancelled",
        Action:""
      },
      {
        id:8,
        key:8,
        Credit:"#4994",
        Name: "Robert",
        Img: img2,
        email: "robert@example.com",
        Amount: "$1,54,220",
        Payment: "Cash",
        Created:"23 Mar 2023",
        Status:"Pending",
        StatusPending:"Pending",
        StatusOverdue:"Overdue",
        StatusDraft:"Draft",
        StatusRecurring:"Recurring",
        StatusCancelled:"Cancelled",
        Action:""
      },
      {
        id:9,
        key:9,
        Credit:"#4995",
        Name: "Johnny",
        Img: img3,
        email: "johnny@example.com",
        Amount: "$1,54,220",
        Payment: "Cash",
        Created:"23 Mar 2023",
        Status:"Overdue",
        StatusPending:"Pending",
        StatusOverdue:"Overdue",
        StatusDraft:"Draft",
        StatusRecurring:"Recurring",
        StatusCancelled:"Cancelled",
        Action:""
      }
    ],
}
