export default {
    Data: [
        {
          Id:1,
          key:1,
          Product: "Lorem ipsum dolor sit amet",
          Quantity: 0,
          Unit: "Pcs",
          Rate: "$120.00",
          Discount: 0,
          Tax: 0,
          Amount: "$120.00",
          Action: ""
        }
    ]
}