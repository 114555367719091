import {
  avatar14,
  img15,
  img16,
  img17,
  img18,
  img19,
} from "../../_components/Imagepath";

export default {
  Data: [
    {
      id: 1,
      key: 1,
      Purchase: "PO-100405361",
      DebitId: "#4987",
      Name: "John Smith",
      Img: avatar14,
      Phone: "+1 843-443-3282",
      Amount: "$1,54,220",
      Payment: "Cash",
      date: "19 Dec 2022, 06:12 PM",
      Status: "Closed",
      Action: "",
    },
    {
      id: 2,
      key: 2,
      Purchase: "PO-100405362",
      DebitId: "#4988",
      Name: "Johnny",
      Img: img15,
      Phone: "+1 917-409-0861",
      Amount: "$2,54,220",
      Payment: "Cheque",
      date: "19 Dec 2022, 06:12 PM",
      Status: "Open",
      Action: "",
    },
    {
      id: 3,
      key: 3,
      Purchase: "PO-100405363",
      DebitId: "#4989",
      Name: "Robert",
      Img: img16,
      Phone: "+1 956-623-2880",
      Amount: "$1,84,220",
      Payment: "Cash",
      date: "19 Dec 2022, 06:12 PM",
      Status: "Closed",
      Action: "",
    },
    {
      id: 4,
      key: 4,
      Purchase: "PO-100405364",
      DebitId: "#4990",
      Name: "Sharonda",
      Img: img17,
      Phone: "+1 707-439-1732",
      Amount: "$6,54,220",
      Payment: "Cheque",
      date: "19 Dec 2022, 06:12 PM",
      Status: "Open",
      Action: "",
    },
    {
      id: 5,
      key: 5,
      Purchase: "PO-100405365",
      DebitId: "#4991",
      Name: "Randall",
      Img: img18,
      Phone: "+1 559-741-9672",
      Amount: "$3,54,220",
      Payment: "Cash",
      date: "19 Dec 2022, 06:12 PM",
      Status: "Closed",
      Action: "",
    },
    {
      id: 6,
      key: 6,
      Purchase: "PO-100405366",
      DebitId: "#4992",
      Name: "Pricilla",
      Img: img19,
      Phone: "+1 989-438-3131",
      Amount: "$1,54,220",
      Payment: "Cheque",
      date: "19 Dec 2022, 06:12 PM",
      Status: "Open",
      Action: "",
    },
  ],
};
