import { Category1, Category2, Category3, Category4, Category5, Category6, img1 } from "../../_components/Imagepath";

export default {
    Data: [
        {
          Id: 1,
          key:1,
          Name: "Advertising",
          CategoryImg:Category1,
          date: "25 Fb 2022, 06:12 PM",
          Added: "Admin",
          img:img1,
          Status: "",
          Action: ""
        },
        {
          Id: 2,
          key:2,
          Name: "Food",
          CategoryImg:Category2,
          date: "19 Dec 2022, 06:12 PM",
          Added: "Admin",
          img:img1,
          Status: "",
          Action: ""
        },
        {
          Id: 3,
          key:3,
          Name: "Marketing",
          CategoryImg:Category3,
          date: "21 Dec 2022, 06:12 PM",
          Added: "Admin",
          img:img1,
          Status: "",
          Action: ""
        },
        {
          Id: 4,
          key:4,
          Name: "Repairs",
          CategoryImg:Category4,
          date: "30 Jan 2022, 06:12 PM",
          Added: "Admin",
          img:img1,
          Status: "",
          Action: ""
        },
        {
          Id: 5,
          key:5,
          Name: "Software",
          CategoryImg:Category5,
          date: "19 Mar 2022, 06:12 PM",
          Added: "Admin",
          img:img1,
          Status: "",
          Action: ""
        },
        {
          Id: 6,
          key:6,
          Name: "Stationary",
          CategoryImg:Category6,
          date: "19 Jan 2022, 06:12 PM",
          Added: "Admin",
          img:img1,
          Status: "",
          Action: ""
        },
        {
          Id: 7,
          key:7,
          Name: "Food",
          CategoryImg:Category2,
          date: "19 Dec 2022, 06:12 PM",
          Added: "Admin",
          img:img1,
          Status: "",
          Action: ""
        },
        {
          Id: 8,
          key:8,
          Name: "Marketing",
          CategoryImg:Category3,
          date: "21 Dec 2022, 06:12 PM",
          Added: "Admin",
          img:img1,
          Status: "",
          Action: ""
        }
       ]
}