import { img2} from "../../_components/Imagepath";

export default {
    Data: [
        {
          id: 1,
          key:1,
          Invoice: "#4987",
          Category: "Food",
          Created:"16 Mar 2022",
          Name: "John Smith",
          email: "+559-741-9672",
          img:img2,
          Total: "$5,54,220",
          Paid:"$3,50,000",
          Payment: "Cash",
          Balance: "$4220",
          Due: "18 Mar 2023",
          Status: "Cancelled",
          Action: ""
        }
       
        
    ]
}