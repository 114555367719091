export default {
    Data: [
        {
          Id: 1,
          key:1,
          Role: "Admin",
          Created: "19 Dec 2022, 06:12 PM",
          Actions: ""
        },
        {
          Id: 2,
          key:2,
          Role: "Customer",
          Created: "28 Nov 2022, 03:25 PM",
          Actions: ""
        },
        {
          Id: 3,
          key:3,
          Role: "Shop Owner",
          Created: "19 Dec 2022, 06:12 PM",
          Actions: ""
        },
        {
          Id: 4,
          key:4,
          Role: "Receptionist",
          Created: "9 Dec 2022, 06:12 PM",
          Actions: ""
        },
        {
          Id: 5,
          key:5,
          Role: "Shop Owner",
          Created: "19 Dec 2022, 06:12 PM",
          Actions: ""
        },
        {
          Id: 6,
          key:6,
          Role: "Admin",
          Created: "19 Dec 2022, 06:12 PM",
          Actions: ""
        },
        {
          Id: 7,
          key:7,
          Role: "Customer",
          Created: "28 Nov 2022, 03:25 PM",
          Actions: ""
        }
       ]
}