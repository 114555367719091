import { return_01, return_02, return_03, return_04, return_05 } from "../../_components/Imagepath";

  
  export default {
    LowstockData: [
        {
          "id": 1,
          "product": "Lenovo 3rd Generation",
          "image": return_01,
          "code": "PPT001",
          "category": "Laptop",
          "soldInStore": 10,
          "onlineInventory": 5
        
        },
        {
          "id": 2,
          "product": "Nike Jordan",
          "image": return_02,
          "code": "PPT005",
          "category": "Shoe",
          "soldInStore": 10,
          "onlineInventory": 10
          
        },
        {
          "id": 3,
          "product": "Apple Series 5 Watch",
          "image": return_03,
          "code": "PPT010",
          "category": "Accessories",
          "soldInStore": 5,
          "onlineInventory": 5
         
        },
        {
          "id": 4,
          "product": "Amazon Echo Dot",
          "image": return_04,
          "code": "PPT104",
          "category": "Electronics",
          "soldInStore": 10,
          "onlineInventory": 10
         
        },
        {
          "id": 5,
          "product": "Lobar Handy",
          "image": return_05,
          "code": "PPT050",
          "category": "Furnitures",
          "soldInStore": 14,
          "onlineInventory": 10,
         
        }
      ]
      
  };
  